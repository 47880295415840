var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md:flex" }, [
      _c(
        "div",
        {
          staticClass: "w-full md:w-1/2 bg-white flex items-center mx-auto",
          staticStyle: { "min-height": "70vh" }
        },
        [
          _c("div", { staticClass: "max-w-sm m-8" }, [
            _c("div", { staticClass: "w-16 h-1 bg-purple-light my-3 md:my-6" }),
            _c(
              "p",
              {
                staticClass:
                  "text-grey-darker text-2xl md:text-3xl font-light mb-8 leading-normal"
              },
              [
                _vm._v(
                  " Sorry, the page you are looking for could not be found. "
                )
              ]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }