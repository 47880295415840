<template>
  <div class="md:flex">
    <div
      class="w-full md:w-1/2 bg-white flex items-center mx-auto"
      style="min-height: 70vh;"
    >
      <div class="max-w-sm m-8">
        <div class="w-16 h-1 bg-purple-light my-3 md:my-6" />
        <p
          class="text-grey-darker text-2xl md:text-3xl font-light mb-8 leading-normal"
        >
          Sorry, the page you are looking for could not be found.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>
 